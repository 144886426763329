<template>

<div class="achtergrond">


  <div class="content_contact">

        <div 
                v-if="event.formulier_response"
                >
                <h1>{{vertaal ( event.data.boodschap )}}</h1>
        </div>

        <div 
                v-else-if="event.data.status != 'ok'  "  
                class="formulier_blok"
                >

                <h1>{{vertaal ( 'contactformulier' )}}</h1>

                 <form @submit.prevent="verstuurFormulier()">

                        <label>{{vertaal ( 'contact_naam' )}}</label>

                        <input 
                                 placeholder=" " 
                                 v-model="event.post.naam"
                                 class="half_groot">

                        <br>

                        <label>{{vertaal ( 'contact_email' )}}</label>
                        <input 
                                  placeholder=" " 
                                  v-model="event.post.email" 
                                  class="half_groot">

                        <br>

                        <label>{{vertaal ( 'contact_vraag' )}}</label>

                        <textarea 
                                v-model="event.post.message" 
                                placeholder=" " 
                                class="groot_veld"></textarea>

                          <br>

                        <button 
                                v-if="event.verzonden != true"
                                type="submit"
                                >
                                {{vertaal (  'verzenden' )}}
                        </button>

                </form>

         </div>
  </div>
</div>


</template>


<script>


import { reactive, computed }      from 'vue';
import store from '@/store'


export default {

    name: 'Contact',

    components: {
      //      LoadingImg
              // TcpDeelnemerLijst,
              // TcpSponsorLijst,
       //      TcpKaart
    },

     setup() {

            const event = reactive({

                     email_adres: computed (() => { return store.state.email_adres  }),


                     formulier_verzonden: false,
                     formulier_response: false,

                     post: {},
                     data: { status: false },
              })







         function vertaal ( code ) {

                let t =  store.getters.getVertaling ( code );

                console.log( "Vertaal: " + code + " - " + t   );          

                return t;
         }






         const verstuurFormulier = async () => {
          
                     if ( event.formulier_verzonden ) return;

                     event.formulier_verzonden = true;

                     event.formulier_response = false;

                     //-----------------------------------------------------//
                  
                     //store.dispatch ( 'setEmail', event.post.email );

                     //-----------------------------------------------------//

                     // let url = "https://vlb.local/app-data/index.php";
                     //let payload = JSON.stringify (this.post );


                     let  payload     = 't=contact_form' ;

                     for ( let key in event.post ) 
                     {
                             payload   += '&' + key + '=' + encodeURI ( event.post [ key ] );
                     }


                //     alert ( payload );


                    //-----------------------------------------------------//


           //          let response = await fetch ( store.state.appDataUrl + "?t=contact_form", {
                     let response = await fetch ( store.state.appDataUrl + "?" + payload , {

                                method: 'post',
                                    headers: {
                                      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
                                    },

                      //          body: payload
                     });

                     event.data =  await response.json ();

                     event.formulier_response = true;
                        
                }



              return {

                  event,
                  verstuurFormulier,
                  vertaal
                 }
      },



      beforeMount(){
              //this.$store.dispatch ( 'setMenu', 'close' );
      },



      mounted() {

            //event.post.email = this.email_adres;

             document.title = 'Contact';

           //this.$store.dispatch ( 'setMenu', 'close' );
      },


      updated() {

                console.log( "Tcp updated" );

                this.event.formulier_verzonden = false;
      },



}



</script>



<style scoped>





.content_contact 
{
    margin: 0 auto;
    padding: 0;

    max-width: 40rem;
}


h1
{
      margin: 1rem 0 0 2rem;
      padding: 0;

      font-size: 1.5rem;

     text-align: left;
}








.formulier_blok 
{
/*  width:  100%;
*/  
    margin:  0;
    padding:  1rem 1rem 2rem 1rem;
    padding:  1rem 0 2rem 0;

    text-align: left;

    background-color: rgb( 240, 240, 40 );

}


label
{
  display: block;

  margin: 1rem 0 0 2rem;

  font-size: 1.1rem;
}


input,
textarea 
{
        font-family: "helvetica", "arial";


        -moz-box-sizing:content-box;
        -webkit-box-sizing:content-box;
        box-sizing:content-box;

        width: 80%;
    width: Calc( 100vw - 3rem );
    max-width: 32rem;

        margin: .25rem  0 .5rem 1rem;
        padding: .5rem;

        border-radius: 3px;

        border: 1px solid rgb(255,255,255);

        border: 1px solid rgb( 230, 230, 233 );
        border: 1px solid rgb( 160, 160, 160 );

        color: rgb( 40, 40, 40 );

        font-size: 1rem;
}


input:focus,
textarea:focus
{
        background-color: #fff;
}


.half_groot 
{
    width: 80%;

    width: Calc( 100vw - 3rem );  
    max-width: 32rem;      
}

.groot_veld 
{
    width: 80%;

    width: Calc( 100vw - 3rem );
    max-width: 32rem;

    height: 10rem;
}





button[type=submit]
{
            font-family: 'Work Sans', Helvetica, Arial, sans-serif;

            font-size: 1.25rem;
            letter-spacing: 1px;

            margin: 1rem 0 0 1.5rem;
            padding: .5rem 1rem .3rem 1rem;

            border: 1px solid rgb( 140, 200,  60 );

            text-decoration: none;

            border-radius: 3rem;

            color: #fff;
            background-color: rgb( 100, 200, 80 );

            cursor: pointer;    


}


button[type=submit]:hover
{
      color: #fff;
      background-color: rgb( 120, 120, 120 );

      border: 1px solid rgb( 120, 120, 120 );

      animation: logoFrames linear 1s;
      animation-timing-function: ease;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-direction: alternate;           
}





@media (min-width: 30rem) 
{
          h1
        {
                margin: 1rem 0 0 2rem;

              font-size: 2rem;
        }

        label
        {
          display: block;

          margin: 1rem 0 0 2.2rem;

          font-size: 1.1rem;
        }


        input,
        textarea 
        {
/*                        margin: .25rem  0 .5rem 2rem;
*/      }

      button[type=submit]
      {


        margin: 1rem 0 0 2rem;
    }




}





@media (min-width: 40rem) 
{
        .achtergrond 
        {

              height:  Calc( 100vh);


            background-image: url( ~@/assets/achtergrond.jpg ); 
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;     


        }


          .content_contact 
        {
              display: flex;
              align-items: center;
              justify-content: center;
              height:  Calc( 100vh - 12rem);


        }


      .formulier_blok 
      {
          width: 37rem;
          margin: 0 auto;

          text-align: left;

              border-radius: .5rem;

              background-color: rgba( 255, 255, 255, .7 );
              background-color: rgba( 240, 240, 244, .7 );
              background-color: rgba( 240, 240, 40, .7 );


  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;


      }




      .half_groot 
      {
          width: 34rem;
          margin-left: 2rem;
      }

      .groot_veld 
      {
          width: 34rem;
          margin-left: 2rem;
      }


}





.slide-in-top {
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



/* ----------------------------------------------
 * Generated by Animista on 2022-12-20 14:7:25
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}







</style>



